<template>
  <v-card flat>
    <v-card-title>Number of calls</v-card-title>
    <v-card-text>
      <v-radio-group
        v-model="mandatoryCalls"
        row
        mandatory
        @change="changeRadioBtn"
      >
        <v-radio
          label="Unlimited"
          :ripple="true"
          value="unlimited"
          color="color_green"
        />
        <v-radio
          label="Limited"
          :ripple="true"
          value="limited"
          color="color_green"
        />
      </v-radio-group>
      <div v-if="mandatoryCalls === 'limited'">
        <v-text-field
          v-model="geoserver_data.callsNum"
          :rules="callsNum_rules"
          outlined
          dense
          full-width
          label="Calls"
          suffix="# per day"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { required, manageLenghtError, getRegExp } from '@/formRules.js';

export default {
  data() {
    return {
      mandatoryCalls: 'unlimited',
      callsNum_rules: [
        ...required,
        ...manageLenghtError(6),
        ...getRegExp('numbers_only')
      ]
    };
  },
  computed: {
    ...mapState(['geoserver_data'])
  },
  created() {
    if (this.geoserver_data.isModifingWorkspace == true) {
      if (this.geoserver_data.workspace.n_calls == -1) {
        this.mandatoryCalls = 'unlimited';
      } else {
        this.mandatoryCalls = 'limited';
        this.geoserver_data.callsNum = this.geoserver_data.workspace.n_calls;
      }
    }
  },
  methods: {
    changeRadioBtn() {
      if (this.geoserver_data.isModifingWorkspace == true) {
        if (this.mandatoryCalls === 'unlimited') {
          this.geoserver_data.callsNum = -1;
        } else {
          this.geoserver_data.callsNum = this.geoserver_data.workspace.n_calls;
          this.geoserver_data.callsNum = null;
        }
      } else {
        if (this.mandatoryCalls === 'unlimited') {
          this.geoserver_data.callsNum = -1;
        } else {
          this.geoserver_data.callsNum = 0;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
